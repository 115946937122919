import * as React from "react";
import Layout from "src/components/layout";

const PageComponent = ({ copy, pageTitle, location, lang }) => {
  return (
    <Layout pageTitle={pageTitle} location={location} lang={lang}>
      <section className="inner-content mx-auto my-20">
        <iframe
          title="video"
          loading="lazy"
          className="wistia_embed mx-auto w-2/3"
          src="//fast.wistia.net/embed/iframe/3rqyvlpaco?videoFoam=true"
          allowFullScreen
          style={{ aspectRatio: "16/9" }}
        ></iframe>

        <ol className="w-2/3 mx-auto list-decimal leading-7">
          {copy.list.map((item, key) => (
            <div key={key} className="my-4">
              <li>{item}</li>
            </div>
          ))}
        </ol>
      </section>
    </Layout>
  );
};

export default PageComponent;

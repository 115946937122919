import * as React from "react";
import PageComponent from "src/components/pages/learn/why-buy-us-life-insurance";
import graph1 from "src/images/graph-1-he.jpg";
import graph2 from "src/images/graph-2-he.jpg";
import graph3 from "src/images/graph-3-he.jpg";

const Page = ({ location }) => {
  const copy = {
    list: [
      <div>
        <span>
          ביטוחי החיים הנמכרים כיום בישראל נחותים בהרבה מאלו הנמכרים בארה”ב, הן
          מצד המחיר והן מצד איכות המוצר. בהשוואה לתושב אמריקאי שיכול להנות
          מביטוחי חיים ריסק/תקופתי במחירים משתלמים מאוד עם חוזים שמבטיחים מחיר
          קבוע שאינו עולה עם השנים לתקופות של 10/15/20/25 ואפילו 30 שנה, תושב
          ישראלי בריא באותה מידה משלם עבור אותה הפוליסה פרמיה גבוהה פי 3 – 4
          והמחיר שלה עולה בכל שנה.
        </span>
        <div className="mx-auto flex flex-row flex-wrap -mx-20 my-2 text-center justify-center">
          {[graph1, graph2, graph3].map((item, key) => (
            <a
              key={key}
              href={item}
              target="_blank"
              rel="noopener noreferrer"
              className="w-6/12 box-border p-5"
            >
              <img src={item} alt="Graph #1" />
            </a>
          ))}
        </div>
        <span>
          לתושב אמריקאי בעל צורך קבוע בנזילות בעת הפטירה יש גם את האפשרות לרכוש
          ביטוח חיים לכל החיים (עד 120) במחיר פרמיה שנתית אחיד. פוליסה מסוג זה
          אפילו לא קיימת בישראל כיום.
        </span>
      </div>,
      `הבעיה היא, שלמעשה אין אף חברה בארה”ב שמסכימה להנפיק פוליסות אלו לתושבים ישראלים.`,
      `אנו מייצגים 2 חברות גדולות של ביטוח חיים בארה”ב שהסכימו להנפיק ביטוחי חיים לתושבים ישראלים מתאימים במחירים הכי טובים שיש בארה”ב. עניין זה מציג הזדמנות עצומה עבור תושבים ישראלים בעלי צורך/רצון בנזילות מובטחת בעת הפטירה במחירים חסכוניים.`,
      `הבעיה היא שבעוד שלתושב ישראלי מותר עפ”י חוק לרכוש ביטוח חיים אמריקאי, אסור לחברות זרות שאינן מורשות בישראל למכור את המוצרים שלהן בישראל. לכן, יש על רוכשים פוטנציאלים לנסוע לארה”ב (לפעמים שוויץ) בכדי לרכוש למעשה את הפוליסה, עניין שדורש השלמה/חתימה של מסמכי בקשה, ובדיקה רפואית.`,
    ],
  };
  return (
    <PageComponent
      lang="he"
      location={location}
      copy={copy}
      pageTitle="מדוע לקנות ביטוח חיים אמריקאי?"
    />
  );
};

export default Page;
